import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiService } from '../../../shared/services/api/api.service';
import { Modes, ProgressView } from './interface/filter-header-interface';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService } from '../../../shared/services/data/data.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ClientService } from '../../../shared/services/client/client.service';
import { KeycloakService } from 'keycloak-angular';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-filter-header',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './filter-header.component.html',
  styleUrl: './filter-header.component.scss',
})
export class FilterHeaderComponent implements OnInit {
  public isProgressView: boolean = false;
  isProgressViewFlag: boolean = false;
  public isFilterView: boolean = false;
  public isTenantSwitchFlag: boolean = false;
  
  public advanceFilter: boolean = false;
  public progressView: Array<ProgressView> = [];
  public selectedView: ProgressView = {};
  public searchText: string = '';
  public routeType: any;
  public historyView: boolean = false;

  @Input() tenants: any[] = [];
  @Input() selectedTenant: any;
  @Output() tenantChange = new EventEmitter<any>();

  // Overall Status to load items in app
  selectedStatus: string;
  statusList: Modes[];

  @Output() currentView = new EventEmitter<string>();

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private route: Router,
    private clientService: ClientService,
    private keycloakService: KeycloakService,
  ) {}

  ngOnInit(): void {
    if (this.dataService.streamID) {
      setTimeout(() => {
        this.getStreamTypeDetails(this.dataService.streamID);
      }, 10);
    } else {
      this.dataService.$streamID.subscribe({
        next: (stream) => {
          if (stream) {
            this.getStreamTypeDetails(stream);
          }
        },
      });
    }
    this.getAllProgressViews();
    this.dataService.searchProgress.subscribe((res) => {
      if (res == null) {
        this.searchText = '';
      }
    });
    this.dataService.viewChange.subscribe((change) => {
      if (change) {
        setTimeout(() => {
          this.changeView();
        }, 200);
      }
    });

    // Detect route changes to check if we are on /dashboard/progress
    this.route.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.isProgressViewFlag =
          event.urlAfterRedirects === '/dashboard/progress';
        if (!this.isProgressViewFlag) {
          this.selectedStatus = 'all'; // Reset the status to 'all' if not on progress view
        }
      });
  }

  private getStreamTypeDetails(stream: string) {
    this.apiService.getStreamTypeDetails(stream).subscribe({
      next: (res) => {
        const labels = res.results[0].labels;
        const types = res.results[0].status_types;
        this.statusList = [{name: 'All', type: 'all'}, ...types];
        this.clientService.setLabels(labels);
        this.onStatusChange(this.statusList[1], true);
      },
      error: (error) => {
        throwError(() => error);
      },
    });
  }

  private changeView() {
    const route = this.route.url.split('/');
    const currentRoute = route[2];
    if (currentRoute === 'divert-lane') {
      this.selectedView = this.progressView[1];
    } else if (currentRoute === 'history-view') {
      this.selectedView = this.progressView[2];
    } else {
      this.selectedView = this.progressView[0];
    }
  }

  onStatusChange = (selectedItem: Modes, skip?: boolean) => {
    this.selectedStatus = selectedItem.name || 'all';
    this.dataService.setStatusMode(selectedItem.type || 'all');
    if(!skip) {
      this.toogleAdvanceFilter();
    }
  };

  onTenantChange = (selectedTenant: string) => {
    this.tenantChange.emit(selectedTenant);
    this.toggleTenantSwitch();
  };

  public getAllProgressViews() {
    this.apiService.getAllViews().subscribe((res) => {
      if (res) {
        // this.progressView = res;
        this.filterProgressViewList(res);
        const router = this.route.url;
        const activeRoute = router.split('/');
        if (activeRoute[2] === 'divert-lane') {
          this.selectedView = res[1];
        } else if (activeRoute[2] === 'history-view') {
          this.selectedView = res[1];
        } else {
          this.selectedView = res[0];
        }
      }
    });
  }

  private filterProgressViewList(view: ProgressView[]) {
      let newArr = view
      newArr.push({ name: 'History View' });
      const index = newArr.findIndex(progress => progress.name == 'Variance View');
      if(index !== -1) {
        let element = newArr.splice(index, 1)[0];
        newArr.push(element);
        this.progressView = newArr;
      }
  }

  public selectView(view: ProgressView) {
    this.dataService.updateInfoLiveConsole(null);
    this.dataService.updateDivertLane(null);
    if (this.selectedView.name == view.name) {
      this.isProgressView = !this.isProgressView;
      return;
    }
    if (view.name !== 'Variance View') {
      this.selectedView = view;
    }
    switch (view.name) {
      case 'Progress View':
        this.route.navigate(['/dashboard/progress']);
        break;
      case 'Inventory View':
        this.route.navigate(['/dashboard/divert-lane']);
        this.dataService.changeLaneMonitor('Divert Lane Monitor');
        break;
      case 'History View':
        this.route.navigate(['/dashboard/history-view']);
        break;
    }
    setTimeout(() => {
      this.isProgressView = !this.isProgressView;
      this.dataService.triggerViewChange(true);
    }, 299);
  }

  public toggleView() {
    this.isProgressView = !this.isProgressView;
  }

  public toggleFilterView() {
    this.isFilterView = !this.isFilterView;
  }

  public toggleTenantSwitch() {
    this.isTenantSwitchFlag = !this.isTenantSwitchFlag;
  }

  public toogleAdvanceFilter() {
    if (!this.isProgressViewFlag) {
      this.advanceFilter = false;
      return;
    }
    this.advanceFilter = !this.advanceFilter;
  }

  public searchProgress() {
    const id = this.searchText.toLowerCase();
    this.dataService.updateInfoLiveConsole(null);
    this.dataService.isLoading = true;
    let type = undefined;
    this.apiService.searchProgressById(id).subscribe({
      next: (res: any) => {
        if (res.results.length > 0) {
          this.dataService.updateInfoLiveConsole(null);
          this.dataService.updateDivertLane(null);
          type = res.results[0].type;
          switch (type) {
            case 'worklist':
              this.dataService.searchProgressData({
                show: true,
                view: 'wave',
                id: { worklist_id: res.results[0].external_work_list_id },
                rerender: true,
              });
              this.route.navigate(
                [
                  `dashboard/progress/worklist/${res.results[0].external_work_list_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              break;
            case 'order':
              this.route.navigate(
                [
                  `dashboard/progress/order/${res.results[0].external_order_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              this.dataService.searchProgressData({
                show: true,
                view: 'order',
                id: { order_id: res.results[0].external_order_id },
                rerender: true,
              });
              break;
            case 'container':
              this.route.navigate(
                [
                  `dashboard/progress/container/${res.results[0].external_container_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              this.dataService.searchProgressData({
                show: true,
                view: 'carton',
                id: { container_id: res.results[0].external_container_id },
                rerender: true,
              });
              break;
            default:
              this.dataService.toasterInfoMessage(
                'No data found with the provided ID'
              );
              break;
          }
        } else {
          this.dataService.toasterInfoMessage(
            'No data found with the provided ID'
          );
        }
        // this.dataService.setLoader(false, 'false');
      },
      error: () => {
        this.dataService.setLoader(false);
      },
    });
    this.dataService.setLoader(false);
  }

  public clearText() {
    this.searchText = '';
    this.dataService.searchProgressData({
      show: false,
      view: undefined,
      id: undefined,
    });
  }
}
